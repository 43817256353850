import ApiCommerce from '@/core/apis/ApiCommerce';
import ApiUtilitario from '@/core/apis/ApiUtilitario';
import GerenciadorAutenticacao from '@/core/gerenciadores/GerenciadorAutenticacao';
import { IItemBuscaRapida } from '@/core/models/Consulta/IItemBuscaRapida';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import { IRetornoConsultaCEP } from '@/core/models/IRetornoConsultaCEP';
import { IRetornoConsultaCNPJ } from '@/core/models/IRetornoConsultaCNPJ';
import { IDTOCotarFreteProduto } from '@/models/DTO/IDTOCotarFreteProduto';
import { IDTOFormaEntregaCalculoFrete } from '@/models/DTO/IDTOFormaEntregaCalculoFrete';
import { IEstado } from '@/models/Entidades/IEstado';
import { IRegiao } from '@/models/Entidades/IRegiao';

class ServicoUtilitario {
    private endPoint = 'utilitarios';

    private apiUtilitario = new ApiUtilitario();

    public async buscaRapidaRegioes(parametrosConsultaRapida: IParametrosBuscaRapida): Promise<IItemBuscaRapida[]> {
      const parametros = new ApiCommerce().obterParametrosBuscaRapida(parametrosConsultaRapida);
      const result: any = await this.apiUtilitario.get(`${this.endPoint}/consultas/regioes${parametros}`);
      return result.data;
    }

    public async obterTodasRegioes(): Promise<IRegiao[]> {
      const result: any = await this.apiUtilitario.get(`${this.endPoint}/consultas/regioes/todas`);
      return result.data;
    }

    public async buscaRapidaEstados(parametrosConsultaRapida: IParametrosBuscaRapida): Promise<IItemBuscaRapida[]> {
      const parametros = new ApiCommerce().obterParametrosBuscaRapida(parametrosConsultaRapida);
      const result: any = await this.apiUtilitario.get(`${this.endPoint}/consultas/estados${parametros}`);
      return result.data;
    }

    public async obterTodosEstados(): Promise<IEstado[]> {
      const result: any = await this.apiUtilitario.get(`${this.endPoint}/consultas/estados/todos`);
      return result.data;
    }

    public async buscaRapidaCidades(parametrosConsultaRapida: IParametrosBuscaRapida): Promise<IItemBuscaRapida[]> {
      const parametros = new ApiCommerce().obterParametrosBuscaRapida(parametrosConsultaRapida);
      const result: any = await this.apiUtilitario.get(`${this.endPoint}/consultas/cidades${parametros}`);
      return result.data;
    }

    public async consultaCep(cep:string): Promise<IRetornoConsultaCEP> {
      const result: any = await this.apiUtilitario.get(`${this.endPoint}/consultas/cep/${cep}`);
      return result.data;
    }

    public async consultaCnpj(cnpj:string): Promise<IRetornoConsultaCNPJ> {
      const result: any = await this.apiUtilitario.get(`${this.endPoint}/consultas/cnpj/${cnpj}`);
      return result.data;
    }

    public async simularFreteProduto(cotacaoProduto:IDTOCotarFreteProduto): Promise<IDTOFormaEntregaCalculoFrete[]> {
      const parceiro = new GerenciadorAutenticacao().obtemIdentificadorParceiro();

      const result: any = await this.apiUtilitario.post(`${parceiro}/carrinhos/frete/produto`, cotacaoProduto);
      return result.data;
    }
}
export default ServicoUtilitario;
